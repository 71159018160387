/* eslint @typescript-eslint/prefer-nullish-coalescing: 1 -- Do not want to break existing code by switching to nullish coalesce blidnly */
import { getLegacyEnvVariable } from '@/app/components/LegacyEnvVariables/util';

import { getEmailTokenCookie } from '../utils/emailTokenCookie';

require('isomorphic-fetch');

export const getGraphQlEndpoint = () => {
  const base = getLegacyEnvVariable('NEXT_PUBLIC_ECOM_API_ENDPOINT');
  return base ? `${base}/graphql` : undefined;
};

export async function parseResponse(response: Response) {
  try {
    if (
      response.status >= 400 ||
      response.status === 204 ||
      response.status === 205
    ) {
      const responseText = await response.text();

      return {
        status: response.status,
        responseText,
      };
    }

    return await response.json();
  } catch (err) {
    return err;
  }
}

async function parseBlob(response: Response): Promise<NodeJS.ReadableStream> {
  // Casting DOM type returns ReadableStream<Uint8Array> to NodeJS stream
  // These should be interchangable, so the typecast is safe
  return response.body as unknown as NodeJS.ReadableStream;
}

interface RequestOptions {
  headers?: Record<string, string>;
  method?: string;
  responseType?: string;
  body?: any;
}

function setOptions(method = 'GET', options: RequestOptions = {}) {
  const ouraKey = getLegacyEnvVariable('OURA_KEY');
  const emailTokenCookie = getEmailTokenCookie();
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    Accept: 'application/json; charset=utf-8',
    ...(options.headers || {}),
  };

  // Add email token to headers if it's set.
  if (emailTokenCookie) headers['X-Email-Token'] = emailTokenCookie;

  if (ouraKey)
    // Bearer key is only available on the server.
    headers['Authorization'] = `Bearer ${ouraKey}`;

  return {
    ...options,
    method,
    headers,
  };
}

class BackendAPI {
  get(path: string, options: RequestOptions = {}) {
    const url = `${getLegacyEnvVariable(
      'NEXT_PUBLIC_ECOM_API_ENDPOINT',
    )}${path}`;
    options = setOptions('GET', options);
    return fetch(url, options).then(parseResponse);
  }

  async getFile(
    path: string,
    options: RequestOptions = {},
  ): Promise<NodeJS.ReadableStream> {
    const url = `${getLegacyEnvVariable(
      'NEXT_PUBLIC_ECOM_API_ENDPOINT',
    )}${path}`;
    options = setOptions('GET', options);
    return fetch(url, options).then(parseBlob);
  }

  post(path: string, data: any, options: RequestOptions = {}) {
    const url = `${getLegacyEnvVariable(
      'NEXT_PUBLIC_ECOM_API_ENDPOINT',
    )}${path}`;
    options = {
      body: JSON.stringify(data),
      ...setOptions('POST', options),
    };
    return fetch(url, options).then(parseResponse);
  }

  put(path: string, data: any, options: RequestOptions = {}) {
    const url = `${getLegacyEnvVariable(
      'NEXT_PUBLIC_ECOM_API_ENDPOINT',
    )}${path}`;
    options = {
      body: JSON.stringify(data),
      ...setOptions('PUT', options),
    };
    return fetch(url, options).then(parseResponse);
  }

  delete(path: string, options: RequestOptions) {
    const url = `${getLegacyEnvVariable(
      'NEXT_PUBLIC_ECOM_API_ENDPOINT',
    )}${path}`;
    options = setOptions('DELETE', options);
    return fetch(url, options).then(parseResponse);
  }

  patch(path: string, options: RequestOptions) {
    const url = `${getLegacyEnvVariable(
      'NEXT_PUBLIC_ECOM_API_ENDPOINT',
    )}${path}`;
    options = setOptions('PATCH', options);
    return fetch(url, options).then(parseResponse);
  }
}

const api = new BackendAPI();
export default api;
