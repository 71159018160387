import wretch, { type WretchError } from 'wretch';
import AbortAddon from 'wretch/addons/abort';
import QueryStringAddon from 'wretch/addons/queryString';

const fetchClient = wretch.bind([AbortAddon, QueryStringAddon]);

export type RequestError = WretchError;

export const nextBackendClient = fetchClient('', { mode: 'cors' })
  .errorType('json')
  .resolve((r) => r.json())
  .catcherFallback((err) => {
    // eslint-disable-next-line no-console
    console.error('Uncaught error that should be logged to datadog', err);
  });

export default fetchClient;
