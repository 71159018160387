import isBrowser from './isBrowser';

export /**
 * Write to browser local storage
 * @param $key string
 * @param value string
 */
const writeToLocalStorage = ($key: string, value: string): void => {
  if (isBrowser()) {
    return window.localStorage.setItem($key, value);
  }
};

export /**
 * Read from browser local storage
 * @param $key string
 * @param value string
 */
const readFromLocalStorage = ($key: string): string | null => {
  if (isBrowser()) {
    return window.localStorage.getItem($key);
  }
  return null;
};
