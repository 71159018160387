import { useEffect, useState } from 'react';

import isBrowser from '@/utils/isBrowser';

import { createTestVideoElement } from './createTestVideoElement';

export function useProgrammaticVideoPlayAllowed(): boolean | undefined {
  const [isAllowed, setIsAllowed] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    if (isBrowser()) {
      const video = createTestVideoElement();
      const p = video.play();
      p.then(() => {
        setIsAllowed(true);
      }).catch(() => {
        setIsAllowed(false);
      });
    }
  }, []);
  return isAllowed;
}
