'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import isBrowser from '@/utils/isBrowser';

import { useProgrammaticVideoPlayAllowed } from '../hooks/useProgrammaticVideoPlayAllowed';

export interface BrowserFeatureContext {
  programmaticVideoPlayAllowed: boolean | undefined;
}

const Context = createContext<BrowserFeatureContext>({
  programmaticVideoPlayAllowed: undefined,
});

export const useBrowserFeatureContext = () => {
  return useContext(Context);
};

const BrowserFeatureProvider = ({ children }: PropsWithChildren) => {
  const programmaticVideoPlayAllowed = useProgrammaticVideoPlayAllowed();

  const value = useMemo(
    (): BrowserFeatureContext => ({
      programmaticVideoPlayAllowed,
    }),
    [programmaticVideoPlayAllowed],
  );

  useEffect(() => {
    if (isBrowser()) {
      document.documentElement.classList.remove('nojs');
    }
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default BrowserFeatureProvider;
