'use client';

import AffiliatesScript from './head/AffiliatesScript';
import AffirmScript from './head/AffirmScript';
import SegmentScript from './head/SegmentScript';

const Scripts = () => {
  return (
    <>
      <SegmentScript />
      <AffiliatesScript />
      <AffirmScript />
    </>
  );
};

export default Scripts;
