import { useQuery } from '@tanstack/react-query';
import { gql, request, type Variables } from 'graphql-request';

import { getGraphQlEndpoint } from '../api/backendAPI';
import type {
  Countries,
  Countries_content_countries,
  CountriesVariables,
} from './types/Countries';

export type CountryDetails = Countries_content_countries;

export const query = gql`
  query Countries($countryCode: String) {
    content {
      countries(countryCode: $countryCode) {
        currency
        currencyForSubscription
        supportedCurrencies
        status
        membershipStatus
        countryCode
        shippingRate {
          description
          price
        }
        eu
        name
        regions {
          label
          value
          labelMsgKey
        }
        merchant {
          prod
          staging
        }
        disallowedProductSkus
        labelMsgKey
        enableForRma
        showNoAdditionalTaxesMessage
      }
    }
  }
`;

export function withQueryKey(vars: CountriesVariables = {}) {
  return {
    queryKey: ['Countries', { vars }],
    queryFn: async () => {
      const byCountryCode = new Map<string, Countries_content_countries>();
      const url = getGraphQlEndpoint()!;

      const data: Countries = await request(url, query, vars as Variables);

      const countries = data.content?.countries ?? [];

      for (const country of countries) {
        if (country?.countryCode) {
          byCountryCode.set(country.countryCode, country);
        }
      }

      return { countries, byCountryCode };
    },
  };
}

export function useSellToCountry(countryCode: string) {
  return useQuery(withQueryKey({ countryCode }));
}
