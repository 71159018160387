import logToDatadog from '../logger/logToDatadog';
import { eraseCookie, getCookie, setCookie } from './cookie';

export const COOKIE_TTL = 24 * 60; // one day
export const COOKIE_NAME = 'email-token';

const isBrowser = typeof window !== 'undefined';

export const getEmailTokenCookie = () => {
  if (isBrowser) {
    const contents = getCookie(COOKIE_NAME);
    if (contents) return contents;
  }
  return null;
};

export const setEmailTokenCookie = (value: string): void => {
  if (isBrowser) {
    const previousEmailToken = getEmailTokenCookie();
    if (!value) {
      eraseCookie(COOKIE_NAME);
      void logToDatadog(
        'my_account',
        'Cleared email token for user.',
        {
          previousEmailToken,
        },
        'info',
      );
    } else {
      setCookie(COOKIE_NAME, value, COOKIE_TTL);
      void logToDatadog(
        'my_account',
        'Set email token for user.',
        {
          currentEmailToken: value,
          previousEmailToken,
        },
        'info',
      );
    }
  }
};
